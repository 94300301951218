import { useRouter } from 'next/router';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { projectFilterFormKeys } from '@/modules/config/project';
import { encode } from 'querystring';

type UrlContextProps = {
    signUpUrl: string;
    signInUrl: string;
    query: string;
};
type UrlProviderProps = {
    children: React.ReactNode;
};

const UrlContext = createContext<UrlContextProps>({
    signUpUrl: '',
    signInUrl: '',
    query: ''
});

export const useUrlContext = () => {
    return useContext(UrlContext);
};

/** 維持しないクエリパラメータ */
const excludeParams = ['keyword', ...projectFilterFormKeys];

const UrlProvider: React.FC<UrlProviderProps> = ({ children }) => {
    const [signUpUrl, setSignUpUrl] = useState('/main/auth/signup');
    const [signInUrl, setSignInUrl] = useState('/main/auth/signin');
    const [query, setQuery] = useState('');
    const router = useRouter();

    useEffect(() => {
        if (!router.isReady) return;
        const routeParams = { ...router.query };
        Object.keys(router.query).forEach((param) => {
            if (
                // クエリがパスパラメーターなら処理から除外する
                router.pathname.includes(param) ||
                // `excludeParams`に設定されているパラメータは除外する
                excludeParams.includes(param)
            ) {
                delete routeParams[param];
            }
        });

        const params = new URLSearchParams(encode(routeParams));
        const paramsString = params.toString() ? `?${params.toString()}` : '';
        setQuery(paramsString);
        setSignUpUrl(`/main/auth/signup${paramsString}`);
        setSignInUrl(`/main/auth/signin${paramsString}`);
    }, [router]);

    return (
        <UrlContext.Provider value={{ signUpUrl, signInUrl, query }}>
            {children}
        </UrlContext.Provider>
    );
};

export default UrlProvider;
