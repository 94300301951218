import Header from '@/components/Header';
import FirstView from '@/components/FirstView';
import Project from '@/components/Project';
import SectionsWrap from '@/components/Shared/SectionsWrap';
import Appeal2 from '@/components/ActualResults';
import Support from '@/components/Support';
import Introduce from '@/components/Introduce';
import Process from '@/components/Process';
import Footer from '@/components/Footer';
import Head from '@/components/Head';
import News from '@/components/News/index';
import Column from '@/components/Column';
import SignUpButton from '@/components/Shared/SignUpButton';
import { NewsDataProps, ColumnDataProps, ProjectProps } from '@/types';
import { useScrollForSignUpButton } from '@/hooks/useScrollForSignUpButton';
import useRedirectVercelApp from '@/hooks/useRedirectVercelApp';
import Media from '@/components/Media';
import PaymentSite from '@/components/PaymentSite';

export type Props = {
    newsContentList: NewsDataProps[];
    columnContentList: ColumnDataProps[];
    superUpstreamExcitingProjects: ProjectProps[];
};

export default function Index({
    newsContentList,
    columnContentList,
    superUpstreamExcitingProjects
}: Props) {
    useRedirectVercelApp();
    const isScrollOver = useScrollForSignUpButton();

    return (
        <>
            <Head />
            <Header />
            {!isScrollOver && <SignUpButton />}
            <main>
                <FirstView />
                <Media text="メディア掲載実績" />
                <Project projects={superUpstreamExcitingProjects} />
                <SectionsWrap>
                    <PaymentSite />
                    <Appeal2 />
                    <Support />
                </SectionsWrap>
                <Introduce />
                <Process />
                <News newsContentList={newsContentList} />
                <Column columnContentList={columnContentList} />
            </main>
            <Footer />
            <div className="h-[76px] md:h-[105px] w-full bg-white"></div>
        </>
    );
}
