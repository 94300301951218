import Link from 'next/link';
import React from 'react';
import { useMedia } from '@/hooks/useMedia';
import { omittedHtmlTagFromText } from '@/modules/column';
import styles from '@/components/Column/ColumnCard/styles.module.css';
import CategoryAsTagChip from '@/components/Column/CategoryAsTagChip';
import { useUrlContext } from '@/provider/urlContext';

type Props = {
    title: string;
    body: string;
    date: string;
    image: string;
    link: string;
    categoriesAsTag: string[];
};

const ColumnCard: React.FC<Props> = ({
    title,
    body,
    date,
    image,
    link,
    categoriesAsTag
}) => {
    const { query } = useUrlContext();
    const isPC = useMedia();
    return isPC ? (
        <div className="border-b-2 border-[#cfcfcf] mt-4 mb-3 pb-3 ">
            <div className="flex items-start justify-center">
                <div className="md:w-[430px]">
                    <Link
                        href={`${link}${query}`}
                        passHref
                        className={styles.link}
                    >
                        <p className="text-lg mb-2 md:w-[430px]">{title}</p>
                    </Link>
                    <p className="h-20 mb-4 text-columnGrey text-xs overflow-hidden">
                        {omittedHtmlTagFromText(body)}
                    </p>
                </div>
                <div>
                    <img
                        src={image}
                        alt="コラム画像"
                        loading="lazy"
                        className="ml-10 w-[280px] h-[180px]"
                    />
                </div>
            </div>
            {categoriesAsTag.length > 0 && (
                <div className="pt-[16px]">
                    {categoriesAsTag.map((item, index) => (
                        <CategoryAsTagChip key={index} label={item} />
                    ))}
                </div>
            )}
        </div>
    ) : (
        <div className=" border-b-2 border-[#cfcfcf] mt-4 mb-3 px-4 pb-3">
            <div className="flex justify-between">
                <div className="w-[65%]">
                    <Link
                        href={`${link}${query}`}
                        passHref
                        className={styles.link}
                    >
                        <p className="text-sm mb-2 font-bold">{title}</p>
                    </Link>
                    <p className="ml-auto text-columnGrey text-sm mt-2">
                        {date}
                    </p>
                </div>
                <div className="ml-[16px] flex items-center">
                    <img
                        src={image}
                        alt="コラム画像"
                        loading="lazy"
                        className="w-[108px] h-[70px]"
                    />
                </div>
            </div>
            {categoriesAsTag.length > 0 && (
                <div className="pt-[16px]">
                    {categoriesAsTag.map((item, index) => (
                        <CategoryAsTagChip key={index} label={item} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default ColumnCard;
