export type Content = {
    question: React.ReactNode;
    answer: React.ReactNode;
};

export const contents: Content[] = [
    {
        question: `案件はどのくらいありますか？`,
        answer: '毎月計300件ほど新着案件を頂いており、ご紹介可能な案件は常時約100件ほどございます。'
    },
    {
        question: 'フルリモートの案件はありますか？',
        answer: '基本リモート+出社の案件が多いですが、全体の2割ほどはフルリモートの案件もございます。'
    },
    {
        question: '稼働率の概念を教えてください。',
        answer: `土日祝日を除く、平日月曜〜金曜の日中8時間×4週(1ヶ月)をフル稼働いただける場合に100％稼働可能と
させていただいております。
とはいえ、案件によっては平日夜や土日を使って成果物ベースでご支援頂いても問題ないという案件も
ございますので、稼働率が低くてもぜひご相談ください。`
    },
    {
        question: '契約形態を教えてください。',
        answer: '一部派遣契約もございますが、基本的に業務委託契約となります。'
    },
    {
        question: `プライム案件のみの紹介は可能ですか？`,
        answer: `可能です。 弊社にございます案件の8割が二次受け、2割がプライム案件となっております。
現在プライム案件のご紹介を増やせるよう新規営業部隊を拡大しておりますので、 ご登録後の面談時に
ご相談くださいませ。`
    },
    {
        question: '自分に合う領域の案件、単価を教えてください。',
        answer: `スキルマッチする案件をご紹介させて頂くため、得意領域を面談にてヒアリングさせて頂いております。
単価相場については、似た経歴の方を参考にお伝えさせていただきますので、ご相談いただければと思います。`
    },
    {
        question: `これからフリーランスになるか迷っています。`,
        answer: `是非一度ご相談ください。
フリーランスになるのは正社員と違って不安定になりますので、ご不安も大きいかと思います。 
ご経歴を伺った上で、フリーランスになった際にご紹介可能な案件がどのくらいあるのか、
他のフリーランスの方の働き方事例などもお伝えさせて頂きます。
ご希望がございましたら、フリーランスにベストな働き方を提供したいと言う思いでProConnectを
立ち上げた弊社代表へ、今後のキャリアについてのご相談をして頂くことも可能です。
まずは一度ご面談にてご相談いただければと存じます。`
    },
    {
        question: `インボイス制度導入に伴い、課税事業者適格請求書発行事業者の登録申請を行いました。申請が完了したらどうすればよいですか？`,
        answer: `ProConnectのマイページから、「インボイス適格事業者番号」と「事業者名」の登録ができます。こちらにご登録頂いているユーザー様においては、課税事業者とのお取引としてインボイスのやりとりをさせていただき、従来通りの料金体系でご案内させていただきます。`
    },
    {
        question: `2023年1月からフリーランスとして活動を初めたばかりなので、まだ免税事業者です。免税事業者はどのような対応になりますか？`,
        answer: (
            <>
                インボイス制度適用後は、消費税の仕入税額控除を適用する要件として、「インボイスの発行と保存」が求められております。
                そこで、インボイスの発行ができない【非適格事業者様(免税事業者等)】との取引では仕入税額控除の適用ができかねる制度となっております。
                そのため弊社としても大変心苦しい決定ではございますが、弊社の方針としては【非適格事業者様(免税事業者等)】の消費税は負担しないという方針」となりますことをご了承いただきたく存じます。
                <br />
                <span className="block mt-3">
                    <span className="block mb-3">
                        例）月150万円(税抜き)でご契約頂いている場合
                        <br />
                        ■インボイス制度開始前
                        <br />
                        月150万円(税抜き)＋15万円(消費税)＝165万円
                    </span>
                    ■インボイス制度開始後、経過措置80％適用 /
                    期間：2023年10月1日〜2026年9月30日
                    <br />
                    【非適格事業者様(免税事業者等)の場合】
                    <br />
                    月150万円(税抜き)-2％＝月147万円(税抜き)
                    <br />
                    月147万円(税抜き)＋14万7千円(消費税)＝161万7千円
                    <span className="text-[#1D55AE] block mb-3">
                        ※元々の計算式は以下となります
                        <br />
                        月150万円(税抜き)×1.08(経過措置80％分)÷1.10(消費税10％を割り戻し)＝147万2,727.2727...
                        <br />
                        端数が発生してしまいますことと、支払い金額を分かりやすく算出させていただくため、経過措置80％期間は【税抜きの業務委託費-2%】とさせていただいております。
                    </span>
                </span>
                【適格事業者様の場合】
                <br />
                月150万円(税抜き)＋15万円(消費税)＝165万円
            </>
        )
    },
    {
        question: `報酬の入金タイミング（支払いサイト）を教えてください。`,
        answer: `最短で稼働月当月末締め翌月9営業日目にお支払いいたします。
（例：稼働月が2025年1月の場合、2025年1月31日締め2月13日支払）\n
※以下の場合は30日サイト（翌月末払い）へ変更される場合がございますので、あらかじめご了承ください。
・必要書類（作業報告書、経費領収書など）が指定期日までに指定の方法でご提出いただけない場合
・差し戻しが発生した場合
・特定条件を満たす案件の場合
・派遣契約の場合
`
    }
];
