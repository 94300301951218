import styles from './styles.module.css';
import Image from 'next/image';

const PaymentSite = () => {
    return (
        <section className="bg-primary" id="payment-site">
            <div className="md:mx-4 lg:w-[1000px] h-auto lg:mx-auto rounded bg-white md:py-20 pt-10 pb-[34px] mb-[24px]">
                <div className="flex items-center md:pl-10 pl-4">
                    <h2 className={`${styles.slash} md:mr-3 mr-2`}>/</h2>
                    <h2 className={`${styles.titleNumber} md:mr-6 mr-3`}>01</h2>

                    <h2
                        className={`${styles.title} md:text-[26px] lg:text-[32px] font-bold text-lg w-60 md:w-auto`}
                    >
                        業界最速水準、
                        <br className="block lg:hidden" />
                        報酬を9営業日でお支払い
                    </h2>
                </div>
                <p className="md:text-lg text-sm md:ml-36 md:mx-[40px] mx-4 md:mt-5 mt-6 mb-10 whitespace-pre-wrap">
                    {`フリーランス向けエージェントの多くが、報酬の支払いサイト「30～45日」と長期化しており、資金繰りに悩むフリーランスの方も少なくありません。\nそんな中ProConnectでは、こうした課題を解消するために、クライアントからの入金を待たずに報酬を「9営業日」でお支払いする仕組みを導入しています。\n業界最速水準の支払いスピードを実現し、フリーランスの皆様・独立を検討している皆様が安心して働ける環境を提供しています。`}
                </p>
                <div className="flex md:flex-col flex-row md:gap-y-5 gap-x-3 justify-center mx-4 md:mx-10">
                    <div className="md:flex-1 mx-auto order-2 md:order-1">
                        <Image
                            src="/images/other-payment.svg"
                            className="hidden md:block"
                            alt="他エージェントの支払サイト"
                            width={913}
                            height={170}
                        />
                        <Image
                            src="/images/other-payment-sp.svg"
                            className="object-contain md:hidden"
                            alt="他エージェントの支払サイト"
                            width={150}
                            height={545}
                        />
                        <p className="text-[#aaa] text-[12px] mt-[4px] lg:mt-1 hidden md:block">
                            ※40日サイトの例
                        </p>
                    </div>
                    <div className="md:flex-1 mx-auto order-1 md:order-2">
                        <Image
                            src="/images/pro-connect-payment.svg"
                            className="object-contain hidden md:block"
                            alt="ProConnectの支払サイト"
                            width={913}
                            height={170}
                        />
                        <Image
                            src="/images/pro-connect-payment-sp.svg"
                            className="object-contain md:hidden"
                            alt="ProConnectの支払サイト"
                            width={150}
                            height={545}
                        />
                        <p className="text-[#aaa] text-[12px] mt-[10px] hidden md:block">
                            ※契約条件や取引状況によっては、9営業日支払いの対象外となる場合がございますのでご了承ください。また、既にご契約の方は順次対応となりますため、担当までご連絡ください。
                        </p>
                    </div>
                </div>
                <div className="md:hidden block mx-4 mt-[10px]">
                    <p className="text-[12px] text-[#aaa]">※40日サイトの例</p>
                    <p className="text-[12px] text-[#aaa] mt-[10px]">
                        ※契約条件や取引状況によっては、9営業日支払いの対象外となる場合がございますのでご了承ください。また、既にご契約の方は順次対応となりますため、担当までご連絡ください。
                    </p>
                </div>
            </div>
        </section>
    );
};

export default PaymentSite;
